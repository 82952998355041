<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="columns is-8 is-variable ">
          <div class="column is-two-thirds has-text-left">
            <h1 class="title is-1 has-text-white">Contact Us</h1>
            <p class="is-size-4">
              Feel free to join our discord, check us out on Twitter as well as
              Facebook!
            </p>
            <div class="social-media buttons">
              <a
                class="button is-large is-dark"
                href="https://discord.gg/fWzrcBxXCB"
                target="_blank"
              >
                <font-awesome-icon :icon="['fab', 'discord']" />
              </a>
              <a
                class="button is-large is-dark"
                href="https://www.facebook.com/Memento-Mori-345797566489276"
                target="_blank"
              >
                <font-awesome-icon :icon="['fab', 'facebook']" />
              </a>
              <a
                class="button is-large is-dark"
                href="https://twitter.com/Memento_Guild"
                target="_blank"
              >
                <font-awesome-icon :icon="['fab', 'twitter']" />
              </a>
            </div>
          </div>
          <div class="column is-one-third has-text-left">
            <p class="is-size-4">
              Contact our GM on discord at
              <strong class="has-text-success">Typhus22#4617</strong>
            </p>
            <hr>
            <p class="is-size-4">
              You can also reach our raid lead on discord at
              <strong class="has-text-success">Xplosives #0619</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Contact",
});
</script>
